import React, { createContext, useContext, useEffect, useState } from "react"
import { useAuth } from "../authentication/AuthContext"
import OrganisationConfig, {
  defaultOrganisationConfig,
  getConfig,
} from "./OrganisationConfig"

const emptyOrganisationConfig = {} as OrganisationConfig

const OrganisationContext = createContext<OrganisationConfig>(
  emptyOrganisationConfig
)

export function useOrganisationContext() {
  return useContext(OrganisationContext)
}

export const OrganisationProvider: React.FC = ({ children }) => {
  const { organisationId } = useAuth()
  const [loading, setLoading] = useState(true)

  const [organisationConfig, setOrganisationConfig] =
    useState<OrganisationConfig>(emptyOrganisationConfig)

  useEffect(() => {
    const fetchOrganisation = async () => {
      let config: OrganisationConfig | undefined
      if (organisationId) {
        config = await getConfig(organisationId)
      }

      setOrganisationConfig(config ?? defaultOrganisationConfig)
      setLoading(false)
    }

    fetchOrganisation()
  }, [organisationId])

  return (
    <OrganisationContext.Provider value={organisationConfig}>
      {!loading && children}
    </OrganisationContext.Provider>
  )
}
