import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore"

type User = {
  id?: string
  objectID?: string // ID from algolia hits
  email: string
  name: string
  roles: string[]
  organisationId: string
  phoneNumber: string
  vehicleRegistration?: string
}

export default User

export const userConverter: FirestoreDataConverter<User> = {
  toFirestore(user: User): DocumentData {
    return user
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): User {
    const data = snapshot.data(options)
    const user: User = {
      id: snapshot.id,
      email: data.email,
      name: data.name,
      roles: data.roles,
      phoneNumber: data.phoneNumber,
      organisationId: data.organisationId,
      vehicleRegistration: data.vehicleRegistration,
    }
    return user
  },
}
