import { useAuth } from "./AuthContext"
import { Navigate, useLocation } from "react-router-dom"
import MissingRoles from "./MissingRoles"

export default function RequireAuth({
  children,
  requireSuper,
}: {
  children: JSX.Element
  requireSuper?: boolean
}) {
  const { isSignedIn, isAdmin, isDriver, isSuperAdmin } = useAuth()
  const location = useLocation()

  if (!isSignedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (requireSuper && !isSuperAdmin) {
    return <Navigate to="/shipments" state={{ from: location }} replace />
  }

  if (isAdmin) {
    return children
  }

  if (isDriver) {
    return <Navigate to="/driver" state={{ from: location }} replace />
  }

  return <MissingRoles />
}
