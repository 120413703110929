import React from "react"
import styles from "./Input.module.scss"

type InputProps = {
  name: string
  label: string
  className?: string
  error?: string
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  [x: string]: any
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ name, label, className, error, fieldError, ...rest }, ref) => {
    const classes = `${className} ${error ? styles.error : ""}`

    return (
      <>
        {!!label && <label htmlFor={name}>{label}</label>}
        <input name={name} className={classes} {...rest} ref={ref} />
        {error && <p className={styles.errorMessage}>{error}</p>}
      </>
    )
  }
)

export default Input
