import NarrowLayout from "../common/layout/NarrowLayout"

export default function MissingRoles() {
  return (
    <NarrowLayout>
      <h1>Missing Roles</h1>
      <br />
      <p>Please contact your administrator to give you permissions</p>
    </NarrowLayout>
  )
}
