import { createSlice } from "@reduxjs/toolkit"

const modalsSlice = createSlice({
  name: "modal",
  initialState: {
    showCreateShipmentModal: false,
  },
  reducers: {
    setCreateShipmentModal(state, action) {
      state.showCreateShipmentModal = action.payload
    },
  },
  extraReducers: {},
})

const modalsReducer = modalsSlice.reducer
export { modalsReducer }
export const { setCreateShipmentModal } = modalsSlice.actions
