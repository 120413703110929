import { Link } from "react-router-dom"
import Logo from "../../assets/deed-logo-white.png"
import { useAuth } from "../../authentication/AuthContext"
import styles from "./Header.module.scss"
import LogoutIcon from "../../assets/door-exit.svg"
import SettingsIcon from "../../assets/settings.svg"
import { useTranslation } from "react-i18next"

export default function Header() {
  const { isSignedIn, isSuperAdmin } = useAuth()
  const { t } = useTranslation()

  const links = [
    {
      url: "/organisation-config-settings",
      text: t("general.settings"),
      icon: SettingsIcon,
      condition: isSignedIn && isSuperAdmin,
    },
    {
      url: "/logout",
      text: t("auth.logout"),
      icon: LogoutIcon,
      condition: isSignedIn,
    },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/" className={styles.logoLink}>
          <img src={Logo} alt="Deed logo" className={styles.logo} />
        </Link>
        <div className={styles.links}>
          {links.map(({ url, text, icon, condition }) =>
            condition ? (
              <Link to={url} className={`${styles.link} link`} key={url}>
                <img src={icon} alt="" />
                {text}
              </Link>
            ) : null
          )}
        </div>
      </div>
    </div>
  )
}
