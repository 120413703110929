import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { flashReducer } from "./flashSlice"
import { modalsReducer } from "./modalsSlice"

export default configureStore({
  reducer: {
    flash: flashReducer,
    modals: modalsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})
