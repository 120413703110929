import { FirebaseError } from "firebase/app"
import { t } from "i18next"

const getFirebaseErrorMessage = (error: FirebaseError) => {
  switch (error.code) {
    case "auth/expired-action-code":
      return t("auth.errors.expiredActionCode")
    case "auth/invalid-action-code":
      return t("auth.errors.invalidActionCode")
    case "auth/user-disabled":
      return t("auth.errors.userDisabled")
    case "auth/user-not-found":
      return t("auth.errors.userNotFound")
    case "auth/weak-password":
      return t("auth.errors.weakPassword")
    case "auth/invalid-email":
      return t("auth.errors.invalidEmail")
    case "auth/wrong-password":
      return t("auth.errors.wrongPassword")
    case "auth/invalid-password":
      return t("auth.errors.invalidPassword")
    case "auth/email-already-exists":
      return t("auth.errors.emailAlreadyExists")
    default:
      return error.message
  }
}

export default getFirebaseErrorMessage
