import Flash from "../flashes/Flash"
import styles from "./NarrowLayout.module.scss"
import useFlash from "../hooks/useFlash"
import { LayoutProps } from "./Layout"
import { Helmet } from "react-helmet"

type NarrowLayoutProps = LayoutProps & { width?: number }

export default function NarrowLayout({
  children,
  title,
  width,
}: NarrowLayoutProps) {
  const { message } = useFlash()

  return (
    <div className={styles.Layout}>
      <div className={styles.NarrowLayout}>
        <Helmet title={title} />
        <div className={styles.wrapper}>{message && <Flash />}</div>
        <div className={styles.wrapper} style={{ maxWidth: width }}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  )
}
