import { Navigate } from "react-router-dom"
import useUrlQuery from "../common/hooks/useUrlQuery"

export default function FirebaseAuthActionHandler() {
  const queryParams = useUrlQuery()
  const mode = queryParams.get("mode")

  switch (mode) {
    case "resetPassword": {
      const code = queryParams.get("oobCode")
      return <Navigate to={`/resetpassword/password?code=${code}`} replace />
    }
    case "verifyEmail":
      return <Navigate to="/verifyemail" replace />
    default:
      return <Navigate to="/" replace />
  }
}
