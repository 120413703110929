export const DELIVERY_STATUS_OUT_FOR_DELIVERY = "out-for-delivery"
export const DELIVERY_STATUS_IN_WAREHOUSE = "in-warehouse"
export const DELIVERY_STATUS_COMPLETED = "completed"
export const DELIVERY_STATUS_FAILED = "failed"
export const DELIVERY_STATUS_MIXED = "mixed"
export const DELIVERY_STATUS_ASSIGNED = "assigned"
export const DELIVERY_STATUS_NEW = "new"
export const DELIVERY_STATUS_OPEN = "open"
export const DELIVERY_STATUS_TRANSPORT_REQUEST = "transport-request"
export const DELIVERY_STATUS_SELF_COLLECT = "self-collect"
