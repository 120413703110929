import { use } from "i18next"
import { initReactI18next } from "react-i18next"

use(initReactI18next).init({
  resources: {
    en: {
      translation: require("./translations/en.json"),
    },
  },
  lng: "en",
  fallbackLng: { default: ["en"] },

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})
