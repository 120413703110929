import React, { createContext, useContext, useEffect, useState } from "react"
import {
  signInWithEmailAndPassword,
  UserCredential,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
} from "firebase/auth"
import { doc, getDoc } from "firebase/firestore"
import * as Sentry from "@sentry/react"

import { auth, db } from "../firebase"
import User, { userConverter } from "../users/User"
import { delay } from "../common/helpers/delay"
import { UserRoles } from "./userVariables"

type AuthContextInterface = {
  currentUser: User | null
  login: (
    email: string,
    password: string,
    callback?: VoidFunction
  ) => Promise<UserCredential>
  signUp: (
    email: string,
    password: string,
    callback?: VoidFunction
  ) => Promise<UserCredential>
  logout: (callback?: VoidFunction) => Promise<void>
  resetPasswordEmail: (email: string) => Promise<void>
  resetPassword: (code: string, newPassword: string) => Promise<void>
  isAdmin: boolean
  isDriver: boolean
  isSignedIn: boolean
  isSuperAdmin: boolean
  organisationId: string
}

const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
)

export function useAuth() {
  return useContext(AuthContext)
}

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  const isSignedIn = !!currentUser
  const isAdmin = currentUser?.roles?.includes(UserRoles.Admin) || false
  const isDriver = currentUser?.roles?.includes(UserRoles.Driver) || false
  const isSuperAdmin =
    currentUser?.roles.includes(UserRoles.SuperAdmin) || false

  const organisationId = currentUser?.organisationId || ""

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      let fullUser: User | null = null
      if (user !== null) {
        const userRef = doc(db, "users", user.uid).withConverter(userConverter)
        const userSnap = await getDoc(userRef)
        fullUser = userSnap.data() || null
      }
      setCurrentUser(fullUser)
      setLoading(false)
      Sentry.setTag("userId", fullUser?.id)
      Sentry.setTag("organisationId", fullUser?.organisationId)
      Sentry.setTag("userEmail", fullUser?.email)
    })

    return unsubscribe
  }, [])

  const login = async (
    email: string,
    password: string,
    callback?: VoidFunction
  ) => {
    const loginResponse = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )

    if (callback) callback()

    return loginResponse
  }

  const signUp = async (
    email: string,
    password: string,
    callback?: VoidFunction
  ) => {
    const signUpResponse = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )

    await delay(5000)
    await signOut(auth)
    await login(email, password)

    if (callback) callback()

    return signUpResponse
  }

  const logout = async (callback?: VoidFunction) => {
    const logoutResponse = await signOut(auth)

    if (callback) callback()

    return logoutResponse
  }

  const resetPasswordEmail = async (email: string) => {
    return sendPasswordResetEmail(auth, email)
  }

  const resetPassword = async (code: string, newPassword: string) => {
    return confirmPasswordReset(auth, code, newPassword)
  }

  const value: AuthContextInterface = {
    currentUser,
    login,
    signUp,
    logout,
    resetPasswordEmail,
    resetPassword,
    isAdmin,
    isDriver,
    isSignedIn,
    isSuperAdmin,
    organisationId,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
