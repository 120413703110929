import { useEffect } from "react"
import useFlash from "../hooks/useFlash"
import { removeFlash } from "../../state/flashSlice"
import CrossIcon from "../../assets/cross.svg"
import CheckIcon from "../../assets/check.svg"
import InfoIcon from "../../assets/alert-circle.svg"
import WarningIcon from "../../assets/alert-diamond.svg"
import ErrorIcon from "../../assets/alert-triangle.svg"
import styles from "./Flash.module.scss"
import store from "../../state/store"
import {
  FLASH_SUCCESS,
  FLASH_WARNING,
  FLASH_ERROR,
  FLASH_INFO,
} from "../constants/flashTypes"

export default function Flash() {
  const { message, type, icon, action, shouldDisappear } = useFlash()
  let displayIcon = icon

  const handleCloseFlash = () => {
    store.dispatch(removeFlash())
  }

  const isDisappearing =
    shouldDisappear || (shouldDisappear === undefined && type === FLASH_SUCCESS)

  useEffect(() => {
    if (isDisappearing) {
      const disappearTimeout = setTimeout(() => {
        store.dispatch(removeFlash())
      }, 5000)
      return () => clearTimeout(disappearTimeout)
    }
  })

  const defaultIconForType = () => {
    switch (type) {
      case FLASH_SUCCESS:
        return CheckIcon
      case FLASH_WARNING:
        return WarningIcon
      case FLASH_ERROR:
        return ErrorIcon
      case FLASH_INFO:
      default:
        return InfoIcon
    }
  }

  if (!icon) {
    displayIcon = defaultIconForType()
  }

  return (
    <div
      className={`${styles.Flash} ${styles[type]} ${
        isDisappearing ? styles.disappearing : ""
      }`}
    >
      <img className={styles.icon} src={displayIcon} alt="" />
      <div className={styles.message}>{message}</div>
      {action && (
        <button
          type="button"
          onClick={action.onClick}
          className={action.className}
        >
          {action.text}
        </button>
      )}
      <button type="button" className={styles.close} onClick={handleCloseFlash}>
        <img src={CrossIcon} alt="close" />
      </button>
    </div>
  )
}
