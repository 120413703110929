import { useState } from "react"
import styles from "./ContactPage.module.scss"
import { SubmitHandler, useForm } from "react-hook-form"
import { t } from "i18next"
import getFirebaseErrorMessage from "../authentication/getErrorMessage"
import Input from "../common/forms/Input"
import NarrowLayout from "../common/layout/NarrowLayout"
import { FirebaseError } from "firebase/app"
import LoadingSpinner from "../common/loading-spinner/LoadingSpinner"
import { useDispatch } from "react-redux"
import { setFlash } from "../state/flashSlice"
import { db } from "../firebase"
import { addDoc, collection } from "firebase/firestore"

type ContactFormInputs = {
  name: string
  email: string
  company: string
  phoneNumber: string
}

export default function ContactPage() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ContactFormInputs>()

  const createContact = async (data: ContactFormInputs) => {
    const contactFormsCollection = collection(db, "contactForms")
    await addDoc(contactFormsCollection, data)
  }

  const onSubmit: SubmitHandler<ContactFormInputs> = async (data) => {
    setError("")
    setLoading(true)

    try {
      await createContact(data)
      const flash = {
        type: "success",
        message: "Successfully submitted contact details",
      }

      dispatch(setFlash(flash))

      reset()
    } catch (err) {
      let errorMessage = t("auth.errors.defaultError")
      if (err instanceof FirebaseError) {
        errorMessage = getFirebaseErrorMessage(err)
      }

      const flash = {
        type: "error",
        message: "Error submitting contact details",
      }

      dispatch(setFlash(flash))

      setError(errorMessage)
    }

    setLoading(false)
  }

  const title = "Contact Form"

  return (
    <NarrowLayout title={title}>
      <h1 className={styles.title}>{title}</h1>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {error && <p className={styles.error}>{error}</p>}
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <label>Name</label>
            <Input
              className="input"
              type="text"
              {...register("name", {
                required: `${t("general.fieldRequired", {
                  field: "Name",
                })}`,
              })}
              error={errors.name?.message}
            />
            <label>Email</label>
            <Input
              className="input"
              type="email"
              {...register("email", {
                required: `${t("general.fieldRequired", {
                  field: "Email",
                })}`,
              })}
              error={errors.email?.message}
            />
            <label>Company</label>
            <Input
              className="input"
              type="text"
              {...register("company", {
                required: `${t("general.fieldRequired", {
                  field: "Company",
                })}`,
              })}
              error={errors.company?.message}
            />
            <button
              type="submit"
              className={`btn btn-primary ${styles.action}`}
              disabled={loading}
            >
              Submit
            </button>
          </form>
        </>
      )}
    </NarrowLayout>
  )
}
