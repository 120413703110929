import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "./AuthContext"

export default function Logout() {
  const navigate = useNavigate()
  const { logout } = useAuth()

  useEffect(() => {
    logout(() => navigate("/login"))
  }, [logout, navigate])

  return null
}
